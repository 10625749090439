import { useState } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";

type ScrollOptions = Parameters<typeof useScroll>[0];

interface UseScrollYProgressProps {
  options?: ScrollOptions;
  length?: number;
}

export const useScrollPositionIndex = ({
  options,
  length = 1,
}: UseScrollYProgressProps) => {
  const [active, setActive] = useState(0);

  const { scrollYProgress } = useScroll(options);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0 && latest < 1) {
      const index = Math.floor(latest * length);
      setActive(index);
    }
  });

  return active;
};
