import { useState, useEffect } from "react";
import { useMotionValueEvent, useMotionValue } from "framer-motion";

interface useUpdateTimeProps {
  time?: number;
  length?: number;
}

export const useUpdateTime = ({
  time = 2000,
  length = 1,
}: useUpdateTimeProps) => {
  const [active, setActive] = useState(0);
  const x = useMotionValue(0);

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      if (count + 1 === length) {
        count = 0;
        x.set(count);
      } else {
        count++;
        x.set(count);
      }
    }, time);

    return () => clearInterval(interval);
  }, [time]);

  useMotionValueEvent(x, "change", (latest) => {
    setActive(latest);
  });

  return active;
};
