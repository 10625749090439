/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Dapper API
 * Public Documentation Dapper
 * OpenAPI spec version: v1
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { CityDetail } from "../../../domain/models/cityDetail";
import type { CityListDetail } from "../../../domain/models/cityListDetail";
import { apiClient } from "../../../../lib/api-client/api-client";
import type { ErrorType } from "../../../../lib/api-client/api-client";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const citiesList = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<CityListDetail[]>(
    { url: "/cities/", method: "GET", signal },
    options,
  );
};

export const getCitiesListQueryKey = () => {
  return ["/cities/"] as const;
};

export const getCitiesListQueryOptions = <
  TData = Awaited<ReturnType<typeof citiesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof citiesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCitiesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof citiesList>>> = ({
    signal,
  }) => citiesList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof citiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CitiesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof citiesList>>
>;
export type CitiesListQueryError = ErrorType<unknown>;

export const useCitiesList = <
  TData = Awaited<ReturnType<typeof citiesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof citiesList>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCitiesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCitiesListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof citiesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof citiesList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCitiesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof citiesList>>> = ({
    signal,
  }) => citiesList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof citiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CitiesListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof citiesList>>
>;
export type CitiesListSuspenseQueryError = ErrorType<unknown>;

export const useCitiesListSuspense = <
  TData = Awaited<ReturnType<typeof citiesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof citiesList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCitiesListSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const citiesRead = (
  id: number,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<CityDetail>(
    { url: `/cities/${id}/`, method: "GET", signal },
    options,
  );
};

export const getCitiesReadQueryKey = (id: number) => {
  return [`/cities/${id}/`] as const;
};

export const getCitiesReadQueryOptions = <
  TData = Awaited<ReturnType<typeof citiesRead>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof citiesRead>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCitiesReadQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof citiesRead>>> = ({
    signal,
  }) => citiesRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof citiesRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CitiesReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof citiesRead>>
>;
export type CitiesReadQueryError = ErrorType<unknown>;

export const useCitiesRead = <
  TData = Awaited<ReturnType<typeof citiesRead>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof citiesRead>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCitiesReadQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCitiesReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof citiesRead>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof citiesRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCitiesReadQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof citiesRead>>> = ({
    signal,
  }) => citiesRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof citiesRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CitiesReadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof citiesRead>>
>;
export type CitiesReadSuspenseQueryError = ErrorType<unknown>;

export const useCitiesReadSuspense = <
  TData = Awaited<ReturnType<typeof citiesRead>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof citiesRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCitiesReadSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
