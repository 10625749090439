/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Dapper API
 * Public Documentation Dapper
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { ChangePasswordByTokenRequest } from "../../../domain/models/changePasswordByTokenRequest";
import type { ChangePasswordByTokenResponse } from "../../../domain/models/changePasswordByTokenResponse";
import type { ChangePasswordRequest } from "../../../domain/models/changePasswordRequest";
import type { ChangePasswordResponse } from "../../../domain/models/changePasswordResponse";
import type { ChangePersonalDataRequest } from "../../../domain/models/changePersonalDataRequest";
import type { ChangePersonalDataResponse } from "../../../domain/models/changePersonalDataResponse";
import type { ChangePersonalNotificationRequest } from "../../../domain/models/changePersonalNotificationRequest";
import type { ChangePersonalNotificationResponse } from "../../../domain/models/changePersonalNotificationResponse";
import type { Error } from "../../../domain/models/error";
import type { GetProfileResponse } from "../../../domain/models/getProfileResponse";
import type { LoginGoogle } from "../../../domain/models/loginGoogle";
import type { LoginGoogleResponse } from "../../../domain/models/loginGoogleResponse";
import type { LoginRequest } from "../../../domain/models/loginRequest";
import type { LoginResponse } from "../../../domain/models/loginResponse";
import type { SendEmailRestorePasswordRequest } from "../../../domain/models/sendEmailRestorePasswordRequest";
import type { SendEmailRestorePasswordResponse } from "../../../domain/models/sendEmailRestorePasswordResponse";
import type { TopicsOfInterestCreate } from "../../../domain/models/topicsOfInterestCreate";
import type { TopicsOfInterestDetail } from "../../../domain/models/topicsOfInterestDetail";
import type { ValidateTokenRequest } from "../../../domain/models/validateTokenRequest";
import type { ValidateTokenResponse } from "../../../domain/models/validateTokenResponse";
import { apiClient } from "../../../../lib/api-client/api-client";
import type {
  ErrorType,
  BodyType,
} from "../../../../lib/api-client/api-client";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const authChangePasswordCreate = (
  changePasswordRequest: BodyType<ChangePasswordRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ChangePasswordResponse>(
    {
      url: "/auth/change-password",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changePasswordRequest,
    },
    options,
  );
};

export const getAuthChangePasswordCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePasswordCreate>>,
    TError,
    { data: BodyType<ChangePasswordRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authChangePasswordCreate>>,
  TError,
  { data: BodyType<ChangePasswordRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authChangePasswordCreate>>,
    { data: BodyType<ChangePasswordRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authChangePasswordCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthChangePasswordCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authChangePasswordCreate>>
>;
export type AuthChangePasswordCreateMutationBody =
  BodyType<ChangePasswordRequest>;
export type AuthChangePasswordCreateMutationError = ErrorType<Error>;

export const useAuthChangePasswordCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePasswordCreate>>,
    TError,
    { data: BodyType<ChangePasswordRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getAuthChangePasswordCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authChangePasswordByTokenCreate = (
  changePasswordByTokenRequest: BodyType<ChangePasswordByTokenRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ChangePasswordByTokenResponse>(
    {
      url: "/auth/change-password-by-token",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changePasswordByTokenRequest,
    },
    options,
  );
};

export const getAuthChangePasswordByTokenCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePasswordByTokenCreate>>,
    TError,
    { data: BodyType<ChangePasswordByTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authChangePasswordByTokenCreate>>,
  TError,
  { data: BodyType<ChangePasswordByTokenRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authChangePasswordByTokenCreate>>,
    { data: BodyType<ChangePasswordByTokenRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authChangePasswordByTokenCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthChangePasswordByTokenCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authChangePasswordByTokenCreate>>
>;
export type AuthChangePasswordByTokenCreateMutationBody =
  BodyType<ChangePasswordByTokenRequest>;
export type AuthChangePasswordByTokenCreateMutationError = ErrorType<Error>;

export const useAuthChangePasswordByTokenCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePasswordByTokenCreate>>,
    TError,
    { data: BodyType<ChangePasswordByTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthChangePasswordByTokenCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authChangePersonalDataCreate = (
  changePersonalDataRequest: BodyType<ChangePersonalDataRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ChangePersonalDataResponse>(
    {
      url: "/auth/change/personal/data/",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changePersonalDataRequest,
    },
    options,
  );
};

export const getAuthChangePersonalDataCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePersonalDataCreate>>,
    TError,
    { data: BodyType<ChangePersonalDataRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authChangePersonalDataCreate>>,
  TError,
  { data: BodyType<ChangePersonalDataRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authChangePersonalDataCreate>>,
    { data: BodyType<ChangePersonalDataRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authChangePersonalDataCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthChangePersonalDataCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authChangePersonalDataCreate>>
>;
export type AuthChangePersonalDataCreateMutationBody =
  BodyType<ChangePersonalDataRequest>;
export type AuthChangePersonalDataCreateMutationError = ErrorType<Error>;

export const useAuthChangePersonalDataCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePersonalDataCreate>>,
    TError,
    { data: BodyType<ChangePersonalDataRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthChangePersonalDataCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authChangePersonalNotificationCreate = (
  changePersonalNotificationRequest: BodyType<ChangePersonalNotificationRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ChangePersonalNotificationResponse>(
    {
      url: "/auth/change/personal/notification/",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changePersonalNotificationRequest,
    },
    options,
  );
};

export const getAuthChangePersonalNotificationCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePersonalNotificationCreate>>,
    TError,
    { data: BodyType<ChangePersonalNotificationRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authChangePersonalNotificationCreate>>,
  TError,
  { data: BodyType<ChangePersonalNotificationRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authChangePersonalNotificationCreate>>,
    { data: BodyType<ChangePersonalNotificationRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authChangePersonalNotificationCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthChangePersonalNotificationCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authChangePersonalNotificationCreate>>
>;
export type AuthChangePersonalNotificationCreateMutationBody =
  BodyType<ChangePersonalNotificationRequest>;
export type AuthChangePersonalNotificationCreateMutationError =
  ErrorType<Error>;

export const useAuthChangePersonalNotificationCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authChangePersonalNotificationCreate>>,
    TError,
    { data: BodyType<ChangePersonalNotificationRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthChangePersonalNotificationCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authGoogleLoginCreate = (
  loginGoogle: BodyType<LoginGoogle>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<LoginGoogleResponse>(
    {
      url: "/auth/google/login/",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: loginGoogle,
    },
    options,
  );
};

export const getAuthGoogleLoginCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authGoogleLoginCreate>>,
    TError,
    { data: BodyType<LoginGoogle> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authGoogleLoginCreate>>,
  TError,
  { data: BodyType<LoginGoogle> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authGoogleLoginCreate>>,
    { data: BodyType<LoginGoogle> }
  > = (props) => {
    const { data } = props ?? {};

    return authGoogleLoginCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthGoogleLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authGoogleLoginCreate>>
>;
export type AuthGoogleLoginCreateMutationBody = BodyType<LoginGoogle>;
export type AuthGoogleLoginCreateMutationError = ErrorType<Error>;

export const useAuthGoogleLoginCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authGoogleLoginCreate>>,
    TError,
    { data: BodyType<LoginGoogle> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getAuthGoogleLoginCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authLoginCreate = (
  loginRequest: BodyType<LoginRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<LoginResponse>(
    {
      url: "/auth/login",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: loginRequest,
    },
    options,
  );
};

export const getAuthLoginCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLoginCreate>>,
    TError,
    { data: BodyType<LoginRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authLoginCreate>>,
  TError,
  { data: BodyType<LoginRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authLoginCreate>>,
    { data: BodyType<LoginRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authLoginCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLoginCreate>>
>;
export type AuthLoginCreateMutationBody = BodyType<LoginRequest>;
export type AuthLoginCreateMutationError = ErrorType<Error>;

export const useAuthLoginCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLoginCreate>>,
    TError,
    { data: BodyType<LoginRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getAuthLoginCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authPersonalTopicsOfInterestRead = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<TopicsOfInterestDetail>(
    { url: "/auth/personal/topics-of-interest/", method: "GET", signal },
    options,
  );
};

export const getAuthPersonalTopicsOfInterestReadQueryKey = () => {
  return ["/auth/personal/topics-of-interest/"] as const;
};

export const getAuthPersonalTopicsOfInterestReadQueryOptions = <
  TData = Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthPersonalTopicsOfInterestReadQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>
  > = ({ signal }) => authPersonalTopicsOfInterestRead(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthPersonalTopicsOfInterestReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>
>;
export type AuthPersonalTopicsOfInterestReadQueryError = ErrorType<unknown>;

export const useAuthPersonalTopicsOfInterestRead = <
  TData = Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthPersonalTopicsOfInterestReadQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAuthPersonalTopicsOfInterestReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthPersonalTopicsOfInterestReadQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>
  > = ({ signal }) => authPersonalTopicsOfInterestRead(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthPersonalTopicsOfInterestReadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>
>;
export type AuthPersonalTopicsOfInterestReadSuspenseQueryError =
  ErrorType<unknown>;

export const useAuthPersonalTopicsOfInterestReadSuspense = <
  TData = Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof authPersonalTopicsOfInterestRead>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAuthPersonalTopicsOfInterestReadSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authPersonalTopicsOfInterestUpdate = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    { url: "/auth/personal/topics-of-interest/", method: "PUT" },
    options,
  );
};

export const getAuthPersonalTopicsOfInterestUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authPersonalTopicsOfInterestUpdate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestUpdate>>,
    void
  > = () => {
    return authPersonalTopicsOfInterestUpdate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthPersonalTopicsOfInterestUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authPersonalTopicsOfInterestUpdate>>
>;

export type AuthPersonalTopicsOfInterestUpdateMutationError =
  ErrorType<unknown>;

export const useAuthPersonalTopicsOfInterestUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthPersonalTopicsOfInterestUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authPersonalTopicsOfInterestPartialUpdate = (
  topicsOfInterestCreate: BodyType<TopicsOfInterestCreate>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<TopicsOfInterestCreate>(
    {
      url: "/auth/personal/topics-of-interest/",
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: topicsOfInterestCreate,
    },
    options,
  );
};

export const getAuthPersonalTopicsOfInterestPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestPartialUpdate>>,
    TError,
    { data: BodyType<TopicsOfInterestCreate> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authPersonalTopicsOfInterestPartialUpdate>>,
  TError,
  { data: BodyType<TopicsOfInterestCreate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestPartialUpdate>>,
    { data: BodyType<TopicsOfInterestCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return authPersonalTopicsOfInterestPartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthPersonalTopicsOfInterestPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestPartialUpdate>>
  >;
export type AuthPersonalTopicsOfInterestPartialUpdateMutationBody =
  BodyType<TopicsOfInterestCreate>;
export type AuthPersonalTopicsOfInterestPartialUpdateMutationError =
  ErrorType<unknown>;

export const useAuthPersonalTopicsOfInterestPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authPersonalTopicsOfInterestPartialUpdate>>,
    TError,
    { data: BodyType<TopicsOfInterestCreate> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthPersonalTopicsOfInterestPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authProfileList = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<GetProfileResponse>(
    { url: "/auth/profile", method: "GET", signal },
    options,
  );
};

export const getAuthProfileListQueryKey = () => {
  return ["/auth/profile"] as const;
};

export const getAuthProfileListQueryOptions = <
  TData = Awaited<ReturnType<typeof authProfileList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof authProfileList>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthProfileListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authProfileList>>> = ({
    signal,
  }) => authProfileList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authProfileList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthProfileListQueryResult = NonNullable<
  Awaited<ReturnType<typeof authProfileList>>
>;
export type AuthProfileListQueryError = ErrorType<Error>;

export const useAuthProfileList = <
  TData = Awaited<ReturnType<typeof authProfileList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof authProfileList>>, TError, TData>
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthProfileListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAuthProfileListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof authProfileList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof authProfileList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthProfileListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authProfileList>>> = ({
    signal,
  }) => authProfileList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof authProfileList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthProfileListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof authProfileList>>
>;
export type AuthProfileListSuspenseQueryError = ErrorType<Error>;

export const useAuthProfileListSuspense = <
  TData = Awaited<ReturnType<typeof authProfileList>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof authProfileList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthProfileListSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authSendEmailRestorePasswordCreate = (
  sendEmailRestorePasswordRequest: BodyType<SendEmailRestorePasswordRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<SendEmailRestorePasswordResponse>(
    {
      url: "/auth/send-email-restore-password",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendEmailRestorePasswordRequest,
    },
    options,
  );
};

export const getAuthSendEmailRestorePasswordCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSendEmailRestorePasswordCreate>>,
    TError,
    { data: BodyType<SendEmailRestorePasswordRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authSendEmailRestorePasswordCreate>>,
  TError,
  { data: BodyType<SendEmailRestorePasswordRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authSendEmailRestorePasswordCreate>>,
    { data: BodyType<SendEmailRestorePasswordRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authSendEmailRestorePasswordCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthSendEmailRestorePasswordCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authSendEmailRestorePasswordCreate>>
>;
export type AuthSendEmailRestorePasswordCreateMutationBody =
  BodyType<SendEmailRestorePasswordRequest>;
export type AuthSendEmailRestorePasswordCreateMutationError = ErrorType<Error>;

export const useAuthSendEmailRestorePasswordCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSendEmailRestorePasswordCreate>>,
    TError,
    { data: BodyType<SendEmailRestorePasswordRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getAuthSendEmailRestorePasswordCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const authValidateTokenCreate = (
  validateTokenRequest: BodyType<ValidateTokenRequest>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ValidateTokenResponse>(
    {
      url: "/auth/validate-token/",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: validateTokenRequest,
    },
    options,
  );
};

export const getAuthValidateTokenCreateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authValidateTokenCreate>>,
    TError,
    { data: BodyType<ValidateTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authValidateTokenCreate>>,
  TError,
  { data: BodyType<ValidateTokenRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authValidateTokenCreate>>,
    { data: BodyType<ValidateTokenRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return authValidateTokenCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthValidateTokenCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authValidateTokenCreate>>
>;
export type AuthValidateTokenCreateMutationBody =
  BodyType<ValidateTokenRequest>;
export type AuthValidateTokenCreateMutationError = ErrorType<Error>;

export const useAuthValidateTokenCreate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authValidateTokenCreate>>,
    TError,
    { data: BodyType<ValidateTokenRequest> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getAuthValidateTokenCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
